<template>
  <div>
    <div class="title">大事记</div>
    <div v-if="associationData" v-loading="loading">
      <div class="content" v-html="associationData.content">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "大事记",
  },
  data(){
    return{
      loading: false,
      associationData:null,
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    async getList() {
      this.loading = true;
      let params = {};
      params.nopage = 1;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.content_type = 12;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      if(res.success) {
        this.loading = false;
        this.associationData=res.data[0]
      }
    },
  }
  
};
</script>
<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>